/*
 * CUSTOM SCSS
 *
 * Should you need to customize the admin, this is the file to do it!
 * Also, if you'd like, you can change the supporting color of the admin
 * by replacing the hex color code of $support-color-admin variable
 */

/* Support color of the admin */
$support-color-admin: #006198;

/* All general style of the admin */
@import '../../../../vendor/click/clickadmin/src/resources/assets/sass/login/app';

/* Place all your custom styles below */
.client-logo-wrapper{ width:25.7rem; height:5.1rem; }

hr{ 
    margin:3rem 0; 
    border-color:$medium-gray;
}

/* Recaptcha */
.g-recaptcha{
    margin-top:1.5rem; margin-bottom:2.5rem; margin-left: calc(50% - 15rem);
    transform:scale(.8);
    transition: opacity .7s ease-out, height .2s ease-out;
}

/* 640+ */
@include breakpoint(medium) {
    .g-recaptcha{ transform:scale(1);}
}