/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Import all Foundation components
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';

// Import fonts style from ClickAdmin::backend
@import '../backend/fonts';

// Import settings from ClickAdmin::backend. For overwriting foundation variables
@import '../backend/settings';

// Include the right mixins
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Import mixins from ClickAdmin::backend
@import '../backend/mixins';

// Import foundation overwrites
@import 'overwrites';

// Import custom style
@import 'login';