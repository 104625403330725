@charset 'UTF-8';

@font-face {
    font-family: 'clickadmin';
    src:url('../fonts/icons/clickadmin.eot');
    src:url('../fonts/icons/clickadmin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icons/clickadmin.woff') format('woff'),
    url('../fonts/icons/clickadmin.ttf') format('truetype'),
    url('../fonts/icons/clickadmin.svg#clickadmin') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icn-']::before,
[class*=' icn-']::before, .icon-font {
    font-family: 'clickadmin' !important; vertical-align:middle;
    speak: none;  
}
.icn-log-out-1::before{ content: "\61"; }
.icn-pencil-1::before{ content: "\62"; }
.icn-bin-2::before{ content: "\63"; }
.icn-preview-1::before{ content: "\64"; }
.icn-add-1::before{ content: "\65"; }
.icn-delete-1::before{ content: "\66"; }
.icn-setting-gears-2::before{ content: "\68"; }
.icn-lock-unlock-2::before{ content: "\69"; }
.icn-profile-1::before{ content: "\6a"; }
.icn-transfer-7::before{ content: "\70"; }
.icn-arrow-down::before{ content: "\71"; }
.icn-arrow-up::before{ content: "\72"; }
.icn-arrow-65::before{ content: "\6c"; }
.icn-arrow-68::before{ content: "\6d"; }
.icn-arrow-67::before{ content: "\6e"; }
.icn-arrow-66::before{ content: "\73"; }
.icn-magnifier::before{ content: "\74"; }
.icn-minus-1::before{ content: "\75"; }
.icn-list-2::before{ content: "\76"; }
.icn-contacts-2::before{ content: "\77"; }
.icn-file-graph-1::before{ content: "\78"; }
.icn-smartphone::before{ content: "\79"; }
.icn-clock-2-1::before{ content: "\42"; }
.icn-calendar-1-1::before{ content: "\41"; }
.icn-question-mark-1::before{ content: "\7a"; }
.icn-upload-12::before{ content: "\43"; }
.icn-info::before{ content: "\44"; }
.icn-crop-2::before{ content: "\46"; }
.icn-undo::before{ content: "\67"; }
.icn-repeat::before{ content: "\45"; }
.icn-arrows::before{ content: "\47"; }
.icn-alert-1::before{ content: "\6b"; }
.icn-download-12::before{ content: "\48"; }
.icn-check-2::before{ content: "\6f"; }
.icn-paper-plane::before{ content: "\49"; }
.icn-cross-1::before{ content: "\4a"; }
.icn-files-4::before{ content: "\4c"; }
.icn-business-chart-2::before{ content: "\4d"; }
.icn-ios-drag::before{ content: "\4b"; }