/*
 * FOUNDATION OVERWRITES
 * @author: Rianne Oosthoek
 *
 * Sometimes using your own classes to style Foundation components isn't enough.
 * You'll need to overwrite the default style of Foundation by using their classes.
 * Place those classes in this file.
 */

$body-font-size: rem-calc(14);
$global-line-height: 1.5;

$dark-primary-color: darken($primary-color, 15%);

body{font-size: $body-font-size; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; line-height:$global-line-height;}
p{font-size: $body-font-size;}
ul, ol, dl{font-size: $body-font-size;}
[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea,
select{font-weight:400;}
label > [type="radio"]{margin-right:0.7rem;}

/* Buttons */
.button-wrapper{
    .menu{margin-bottom:1rem;}
}
.button-group{
    .button:not(:last-child){border-color:$white;}
    &.tiny .icn{font-size:1.3rem; line-height:1.3;}
}
.button{
    line-height:$global-line-height;
    border:0;
}