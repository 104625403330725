/*
 * CUSTOM STYLES
 *
 * All of your custom css classes and styles can be placed below.
 * Do NOT forgot to add comments above the different sections of styles.
 * For instance: styles that refer to news, add the comment /* NEWS * / above.
 *               styles that refer to product detail page, add the comment /* PRODUCT DETAIL * /
 *               Etc.
 */

/* Login page */
.non-auth{overflow-y:visible;}
.login-wrapper{
    width:100%; max-width:46rem; padding:$global-padding 0;
    font-size:$body-font-size;
}

.login-content{
     padding:($global-padding * 2) $global-padding;
     background-color:$white;
}

.powered-by{
    margin-top:1.5rem;
    font-size:1.1rem; color:$body-font-color;
    display:inline-block;
}

.powered-by-logo{margin-left:.5rem;}

.client-logo-wrapper{
    width:10rem; height:10rem; margin:0 auto 3rem auto;
    display:block;
}

/* Alert boxes */
.alert-box{
    @include alert;
    .close{ @include alert-close; }
    &.radius{border-radius:$alert-radius;}
    &.primary{ @include alert-style($primary-color); }
    &.secondary{ @include alert-style($secondary-color); }
    &.success{ @include alert-style($success-color); }
    &.alert{ @include alert-style($alert-color); }    
    &.warning{ @include alert-style($warning-color); } 
    &.alert-close{opacity:0;}
    ul{margin-bottom:0;}
    a{
        color:$white; text-decoration:underline;
        &:hover{text-decoration:none;}
    }
}

/* Media query min-height: 570px */
@media only screen and (min-height: 57rem){

    /* IE fixes */
    .is-ie9{
        &.login-wrapper{
            width:100%;
            top:25%;
        }
    }

    /* Login page */
    .login-wrapper{
        @include position($position: absolute, $top: 50%, $left: 50%);
        padding:0;
        transform:translate(-50%, -50%);
    }

}

/* Media query min-width: 480px */
@include breakpoint(medium) {

    /* Login page */
    .login-wrapper{margin:0 auto;}

}